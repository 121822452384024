'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.getAccountNumber = exports.isAuthenticated = exports.getErrorMessage = void 0
const core_1 = require('@telekomconsalting/core')
const dex_guru_internal_sdk_1 = require('@telekomconsalting/dex-guru-internal-sdk')
const injected_connector_1 = require('@telekomconsalting/injected-connector')
const walletconnect_connector_1 = require('@telekomconsalting/walletconnect-connector')
const getErrorMessage = (error) => {
  var _a
  if (error instanceof injected_connector_1.NoEthereumProviderError) {
    return 'Looks like you do not have the MetaMask wallet extension. Please download it and try again'
  } else if (error instanceof core_1.UnsupportedChainIdError) {
    return 'You are trying to connect with an unsupported network. Please change the network and try again.'
  } else if (
    error instanceof injected_connector_1.UserRejectedRequestError ||
    error instanceof walletconnect_connector_1.UserRejectedRequestError
  ) {
    return 'Please authorize this website to access your Ethereum account.'
  } else if (
    (_a = error.message) === null || _a === void 0
      ? void 0
      : _a.includes('Already processing eth_requestAccounts')
  ) {
    return 'You should unlock your wallet provider first. Pending eth_requestAccounts request.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}
exports.getErrorMessage = getErrorMessage
const isAuthenticated = () =>
  !!dex_guru_internal_sdk_1.auth.getAccountId() && !!dex_guru_internal_sdk_1.auth.getAccessToken()
exports.isAuthenticated = isAuthenticated
const getAccountNumber = (account) => parseInt(account.slice(2, 10), 16)
exports.getAccountNumber = getAccountNumber
