'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.ProviderName = void 0
var ProviderName
;(function (ProviderName) {
  ProviderName['MetaMask'] = 'MetaMask'
  ProviderName['TrustWallet'] = 'TrustWallet'
  ProviderName['WalletConnect'] = 'WalletConnect'
  ProviderName['Unknown'] = 'Unknown'
})((ProviderName = exports.ProviderName || (exports.ProviderName = {})))
